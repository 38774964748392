<template>
    <div>
        <Pane />

        <a-card class="container">

            <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit"
                :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请人">
                            <a-button block style="text-align: left">
                                {{
                user.userName
            }}
                            </a-button>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请部门">
                            <a-button block style="text-align: left">
                                {{
                department.deptUniqueName
            }}
                            </a-button>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请人手机号">
                            <a-input v-decorator="['applicantMobile',
                { rules: [{ required: true, message: '请输入' }] },]" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="申请车型">
                            <a-select style="width: 100%" v-decorator="[
                'carType',
                { rules: [{ required: true, message: '请选择！' }] },
            ]">
                                <a-select-option
                                v-for="item in carList"
                                :key="item.value"
                                :value="item.value"
                                >
                                {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="乘车人员">
                            <a-input v-decorator="['personnel',
                { rules: [{ required: true, message: '请输入' }] },]" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="起止时间">
                            <a-range-picker style="width: 100%" :placeholder="[]" format="YYYY-MM-DD HH:mm:ss"
                                v-decorator="[
                'date',
                {
                    rules: [{ required: true, message: '请选择！' }],
                },
            ]" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="用车事由">
                            <a-input v-decorator="['reason',
                { rules: [{ required: true, message: '请输入' }] },]" />
                        </a-form-item>
                    </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="开始地址">
                            <a-input v-decorator="['addressStart',
                { rules: [{ required: true, message: '请输入' }] },]" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="结束地址">
                            <a-input v-decorator="['addressEnd',
                { rules: [{ required: true, message: '请输入' }] },]" />
                        </a-form-item>
                    </a-col>


                </a-row>

                <div class="footer">
                    <a-space>
                        <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
                        <a-button @click="$close($route.path)">关闭</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import request from "@/api/request";
import organization from "@/mixins/organization";
import { mapGetters, mapState } from "vuex";

function save(data) {
    return request({
        url: "/office-service/oa/use/car/add",
        method: "post",
        data
    });
}
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            saveLoading: false,
        };
    },
    mixins: [organization],
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["department"]),
        ...mapGetters("setting", ["findDataDict"]),
        carList() {
            return this.findDataDict("oa_use_car_type");
        },
    },
    mounted() {

    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            console.log(this.department)
            const that = this;
            this.form.validateFields((err, values) => {
                if (!err) {
                    that.$confirm({
                        title: "本次提交将发起审批流程，是否继续？",
                        onOk() {
                            that.saveLoading = true;
                            const { date } = values;
                            let startDate, endDate;
                            if (Array.isArray(date) && date.length === 2) {
                                startDate = date[0].format("YYYY-MM-DD HH:mm:ss");
                                endDate = date[1].format("YYYY-MM-DD HH:mm:ss");
                            }
                            const json = {
                                ...values,
                                timeStart: startDate,
                                timeEnd: endDate,
                                applicant: that.user.userName,
                                applicantId: that.department.userId,
                                applyDeptId: that.department.deptId,
                                applyDeptName: that.department.deptUniqueName,
                            }
                            delete json.date
                            save(json).then(() => {
                                that.$close(that.$route.path);
                            }).finally(() => {
                                that.saveLoading = false;
                            })
                        }
                    });

                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.pane {
    background-color: #fff;
    padding: 16px 32px;
    position: relative;

    .title {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
    }

    .remarks {
        margin-top: 8px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.45);
    }
}

.footer {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>